import React, { Component } from "react";
import Auth from "./Auth";
import firebase from "firebase";
import { auth, base } from "./base";

class Jumbotron extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jumbotronHeading: "",
      jumbotronLead: "",
      jumbotronImg: "",
      bannerTxtColor: "",
    };
  }

  componentWillMount() {
    auth.onAuthStateChanged(() => {
      base.syncState(`jumbotronHeading/${this.props.uid}`, {
        context: this,
        state: "jumbotronHeading",
      });

      base.syncState(`jumbotronLead/${this.props.uid}`, {
        context: this,
        state: "jumbotronLead",
      });

      base.syncState(`jumbotronImg/${this.props.uid}`, {
        context: this,
        state: "jumbotronImg",
      });

      base.syncState(`bannerTxtColor/${this.props.uid}`, {
        context: this,
        state: "bannerTxtColor",
      });
    });
    this.updateJombotron = this.updateJombotron.bind(this);
  }

  updateJombotron(changeThis) {
    let theUpdate = document.querySelector("[name='" + changeThis + "']").value;
    this.setState({
      [changeThis]: theUpdate,
    });
    document.querySelector("[name='" + changeThis + "']").value = "";
  }

  render() {
    const teasers = this.props.teasers;

    localStorage.setItem("jumbotronHeading", this.state.jumbotronHeading);
    localStorage.setItem("jumbotronLead", this.state.jumbotronLead);
    localStorage.setItem("jumbotronImg", this.state.jumbotronImg);
    localStorage.setItem("bannerTxtColor", this.state.bannerTxtColor);

    return (
      <div>
        <section
          className="jumbotron text-center"
          style={{ backgroundImage: "url(" + this.state.jumbotronImg + ")" }}
        >
          {this.props.editMode ? (
            <div className="form-group editMode">
              <input
                className="form-control"
                placeholder="image 1200px width minimum"
                name="jumbotronImg"
              />
              <button
                className="btn btn-primary btn-block"
                onClick={this.updateJombotron.bind(this, "jumbotronImg")}
              >
                Change Background Image
              </button>
            </div>
          ) : null}

          <div className="container">
            <h1 className={"jumbotron-heading " + this.state.bannerTxtColor}>
              {this.state.jumbotronHeading}
            </h1>{" "}
            {this.props.editMode ? (
              <div className="form-group editMode">
                <input
                  className="form-control"
                  placeholder={"Change: " + this.state.jumbotronHeading}
                  name="jumbotronHeading"
                />
                <button
                  className="btn btn-primary btn-block"
                  onClick={this.updateJombotron.bind(this, "jumbotronHeading")}
                >
                  Change Banner Title
                </button>
              </div>
            ) : null}
            <p className={"lead " + this.state.bannerTxtColor}>
              {this.state.jumbotronLead}
            </p>
            {this.props.editMode ? (
              <div className="form-group editMode">
                <input
                  className="form-control"
                  placeholder={"Change: " + this.state.jumbotronLead}
                  name="jumbotronLead"
                />
                <button
                  className="btn btn-primary btn-block"
                  onClick={this.updateJombotron.bind(this, "jumbotronLead")}
                >
                  Change Banner Text
                </button>
              </div>
            ) : null}
            {this.props.editMode ? (
              <div className="form-group editMode">
                <label>Select banner text color</label>
                <select
                  name="bannerTxtColor"
                  className="form-control"
                  onChange={this.updateJombotron.bind(this, "bannerTxtColor")}
                >
                  <option>Select banner text color</option>
                  <option value="lightTxt">Light Colored Text</option>
                  <option value="darkTxt">Dark Colored Text</option>
                </select>
              </div>
            ) : null}
          </div>
          <div className="row  showAt768">
            <div className="col-md-12 ">
              <ul className="nav justify-content-center">
                {teasers.length > 0
                  ? teasers.map((tease, i) => {
                      return (
                        <li
                          key={i}
                          className="nav-item"
                          onClick={this.props.scrollWindow.bind(
                            this,
                            tease.teaserTitle
                          )}
                        >
                          <strong className="nav-link">
                            {tease.teaserTitle}
                          </strong>

                          {this.props.editMode ? (
                            <div className="input-group editMode">
                              <input
                                type="text"
                                className={"form-control selectEdit" + i}
                                placeholder="Teaser Title"
                                name="teaserTitle"
                              />
                              <span className="input-group-btn">
                                <button
                                  className="btn btn-secondary"
                                  onClick={this.props.editMainFocus.bind(
                                    this,
                                    i,
                                    "teaserTitle"
                                  )}
                                >
                                  {" "}
                                  Update
                                </button>
                              </span>
                            </div>
                          ) : null}
                        </li>
                      );
                    })
                  : null}
              </ul>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Jumbotron;
