import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";

class Blog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      result: null,
      feed: "",
      value: this.props.rssFeed,
      blogScroll: 0,
      maxPost: 0,
    };
    this.getRssFeed = this.getRssFeed.bind(this);
    this.viewPosts = this.viewPosts.bind(this);
  }

  viewPosts(direction) {
    /*   [].forEach.call(document.querySelectorAll(".post[data-num]"), function (e) {
         e.classList.add("hide");
       });
       if (document.querySelector(".fadeIn")) {
         [].forEach.call(document.querySelectorAll(".fadein"), function (e) {
           e.classList.remove("fadeIn");
         });
       }
   */
    const cardLength = this.state.feed.length;

    let blogScroll = this.state.blogScroll;
    //let visibleCards = blogScroll / cardLength;

    if (direction === "next") {
      blogScroll = blogScroll + 1;
      if (blogScroll >= cardLength) {
        blogScroll = 0;
      }
      this.setState({
        blogScroll,
      });
    } else {
      blogScroll = blogScroll - 1;
      if (blogScroll < 0) {
        blogScroll = cardLength - 1;
      }
      this.setState({
        blogScroll,
      });
    }

    if (document.querySelector(".post[data-num='" + blogScroll + "']")) {
      document
        .querySelector(".post[data-num='" + blogScroll + "']")
        .classList.remove("hide");
      document
        .querySelector(".post[data-num='" + blogScroll + "']")
        .classList.add("fadeIn");
    }
  }

  getRssFeed(whatFeed) {
    fetch(whatFeed)
      .then((res) => res.text())
      .then(
        (result) => {
          let tempResult = JSON.parse(result);
          this.setState(
            {
              isLoaded: true,
              feed: JSON.parse(result),
            },
            () => {
              this.setState({
                maxPost: tempResult.length,
              });
            }
          );
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  componentDidMount() {
    let blogAddress = this.state.value;
    this.getRssFeed(
      "https://mechanized-aesthetics.net/php-relays/social-app-rss.php?q=" +
      blogAddress
    );
  }

  render() {
    return (
      <div className="album py-5" data-section="blogSection">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1>
                RSS Feed/Bog Posts:{" "}
                {this.state.maxPost !== 0
                  ? this.state.blogScroll + 1 + "/" + this.state.maxPost
                  : null}
              </h1>
              <div className="btn-group btn-block">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={this.viewPosts.bind(this, "previous")}
                >
                  Previous Post
                </button>

                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={this.viewPosts.bind(this, "next")}
                >
                  Next Post
                </button>
              </div>
            </div>
            {this.state.feed ? (
              <div className="col-md-12"><h1>{this.state.feed[this.state.blogScroll].title}</h1><hr /><i>{this.state.feed[this.state.blogScroll].pubDate}
              </i><div data-content={[this.state.blogScroll]} >{ReactHtmlParser(this.state.feed[this.state.blogScroll].description)}</div></div>
            ) : (
              <div className="loader center" />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Blog;


/*

 ReactHtmlParser(this.state.feed)

[
  {"title":"AZ Basset Rescue Halloween","link":"https:\/\/our-basset-hounds.blogspot.com\/2022\/07\/az-basset-rescue-halloween.html",
"description":"<iframe style=\"width: 100%; height: auto; min-height: 270px;\" src=\"https:\/\/www.youtube.com\/embed\/iFon4-YC2RU\" title=\"YouTube video player\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture\" allowfullscreen><\/iframe> ",
"pubDate":"Fri, 22 Jul 2022 16:35:00 ","guid":"tag:blogger.com,1999:blog-4074357836862008169.post-2995057506584223041"},

['title' => $item_title, 'link' => $item_link, 'description' => $item_desc, 'pubDate' => $item_pubDate, 'guid' => $item_guid]);
*/