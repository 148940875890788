import React, { Component } from "react";
import firebase from "firebase";
import { auth, base } from "./base";
import SocialMediaTop from "./SocialMediaTop";
import Jumbotron from "./Jumbotron";
import MainFocus from "./MainFocus";
import Carousel from "./Carousel";
import OptionSection from "./OptionSection";
import ListSection from "./ListSection";
import SocialMedia from "./SocialMedia";
import Contact from "./Contact";
import Design from "./Design";
import LoginForm from "./LoginForm";
import Blog from "./Blog";
import "../node_modules/react-quill/dist/quill.snow.css"; // ES6

/*import "../node_modules/@fortawesome/fontawesome-free/css/all.min.css"; //https://fontawesome.com/icons?d=gallery*/

class App extends Component {
  constructor() {
    super();
    this.state = {
      editMode: false,
      copyrightYr: "",
      uid: "5TAuI6m5oqhd1dWcTZLxhc20Xhl1", //Dave
      //uid: "d59OkXCzYSe0GJ3xNIyAMXrYXFW2", //Wolff Motell NOT IN USE - wolff motel data was swtiched to public json file
      //uid: "YRvF9srJvicNtiIqQ5nCmFD8YHt1",//the rockers example
      //uid: "7IoD6zEANlVxeNeLw001O22k8JI2",//bassethound example
      isAnonymous: true,
      adminLogin: false,
      teasers: [],
      design: "",
      topTitle: "",
      address: "",
      phone: "",
      email: "",
      loggedIn: "",
      adminLogin: false,
      alertMessage: "",
      rssFeed: "",
    };

    this.toggleEdit = this.toggleEdit.bind(this);
    this.updateThis = this.updateThis.bind(this);
    this.editMainFocus = this.editMainFocus.bind(this);
    this.scrollWindow = this.scrollWindow.bind(this);
    this.clearForms = this.clearForms.bind(this);
    this.updateDesign = this.updateDesign.bind(this);
    this.logOut = this.logOut.bind(this);
    this.submitLogin = this.submitLogin.bind(this);
    this.adminLogin = this.adminLogin.bind(this);
    this.logOut = this.logOut.bind(this);
    this.changeUserStatus = this.changeUserStatus.bind(this);
    this.submitSignUp = this.submitSignUp.bind(this);
    this.toggleAlert = this.toggleAlert.bind(this);
  }

  scrollWindow(scrollTarget) {
    if (this.state.editMode === false) {
      document
        .querySelector("[data-target='" + scrollTarget + "']")
        .scrollIntoView({ behavior: "smooth" });
      console.log("should be scrolling");
    }
  }

  toggleAlert(toggleWhat) {
    this.setState({
      alertMessage: toggleWhat,
    });
  }

  updateDesign() {
    let design = { ...this.state.design };
    const whichDesign = document.getElementById("designOption").value;
    design = whichDesign;
    document.querySelector("body").setAttribute("data-design", whichDesign);

    this.setState({ design });
  }

  componentWillMount() {
    let theUser = "5TAuI6m5oqhd1dWcTZLxhc20Xhl1";
    let guestUser = this.state.uid;
    let url = window.location;
    let urlVals = {};
    (url + "?")
      .split("?")[1]
      .split("&")
      .forEach(function (pair) {
        pair = (pair + "=").split("=").map(decodeURIComponent);
        if (pair[0].length) {
          urlVals[pair[0]] = pair[1];
          if (pair[0] === "user") {
            guestUser = pair[1];
          }
        }
      });

    auth.onAuthStateChanged((user) => {
      if (user) {
        theUser = user.uid;
        this.setState({
          loggedIn: user.email,
          uid: theUser,
        });
      } else {
        theUser = guestUser;
        this.setState({
          loggedIn: "",
          uid: guestUser,
        });
      }

      //?user=YRvF9srJvicNtiIqQ5nCmFD8YHt1&
      //?user=7IoD6zEANlVxeNeLw001O22k8JI2&

      base.syncState(`email/${theUser}`, {
        context: this,
        state: "email",
      });

      base.syncState(`design/${theUser}`, {
        context: this,
        state: "design",
      });

      base.syncState(`teasers/${theUser}`, {
        context: this,
        state: "teasers",
      });

      base.syncState(`topTitle/${theUser}`, {
        context: this,
        state: "topTitle",
      });

      base.syncState(`address/${theUser}`, {
        context: this,
        state: "address",
      });

      base.syncState(`phone/${theUser}`, {
        context: this,
        state: "phone",
      });

      base.syncState(`rss/${theUser}`, {
        context: this,
        state: "rssFeed",
      });
    });

    var d = new Date();
    var yr = d.getFullYear();

    this.setState({
      copyrightYr: yr,
    });
  }

  clearForms() {
    [].forEach.call(document.querySelectorAll("input, textarea"), function (e) {
      e.value = "";
    });
    if (document.querySelector(".ql-editor")) {
      [].forEach.call(document.querySelectorAll(".ql-editor"), function (e) {
        e.innerHTML = "";
      });
    }

    let selects = document.getElementsByTagName("select");
    for (let i = 0; i < selects.length; i++) {
      selects[i].selectedIndex = 0;
    }
  }

  updateThis(changeThis, regex) {
    let theUpdate = document.querySelector("[name='" + changeThis + "']").value;
    this.setState({
      [changeThis]: theUpdate,
    });
    document.querySelector("[name='" + changeThis + "']").value = "";
  }

  editMainFocus(num, changeThis) {
    let theUpdate = document.querySelector(
      "input.selectEdit" + num + "[name='" + changeThis + "']"
    ).value;
    if (theUpdate.length === 0) {
      alert("The input field was empty.");
      return false;
    }
    let tempTeaser = this.state.teasers;

    switch (changeThis) {
      case "focusTeaser":
        tempTeaser[num].focusTeaser = theUpdate;
        break;
      case "teaserTitle":
        tempTeaser[num].teaserTitle = theUpdate;
        break;
      case "teaserText":
        tempTeaser[num].teaserText = theUpdate;
        break;
      default:
        tempTeaser[num].teaserImg = theUpdate;
    }

    this.setState({
      teasers: tempTeaser,
    });

    this.clearForms();
  }

  toggleEdit() {
    if (this.state.editMode === false) {
      this.setState({
        editMode: true,
      });
      //document.querySelector(".p-2.hide").classList.remove("hide");
    }
    if (this.state.editMode === true) {
      this.setState({
        editMode: false,
      });
      //document.querySelector("a.p-2[href='']").classList.add("hide");
    }
  }

  adminLogin() {
    let toggleLogin = this.state.adminLogin;
    if (toggleLogin === false) {
      this.setState({
        adminLogin: true,
      });
    } else {
      this.setState({
        adminLogin: false,
      });
    }
  }

  //listen for auth state changes
  changeUserStatus() {
    const editPanel = document.querySelector("#editOptions");
    auth.onAuthStateChanged((user) => {
      if (user) {
        //console.log("user: " + JSON.stringify(user));
        this.setState({
          loggedIn: user.email,
        });
        // editPanel.classList.remove("hide");
      } else {
        this.setState({
          loggedIn: "",
        });
        // editPanel.classList.add("hide");
      }
    });
  }

  saveToLocalData() {
    const json = {
      address: localStorage.getItem("address"),
      bannerTxtColor: localStorage.getItem("bannerTxtColor"),
      design: localStorage.getItem("design"),
      donate: localStorage.getItem("donate"),
      email: localStorage.getItem("email"),
      events: localStorage.getItem("events"),
      facebook: localStorage.getItem("facebook"),
      instagram: localStorage.getItem("instagram"),
      jumbotronHeading: localStorage.getItem("jumbotronHeading"),
      jumbotronImg: localStorage.getItem("jumbotronImg"),
      jumbotronLead: localStorage.getItem("jumbotronLead"),
      linkedin: localStorage.getItem("linkedin"),
      phone: localStorage.getItem("phone"),
      rss: localStorage.getItem("rss"),
      secondaryContent: localStorage.getItem("secondaryContent"),
      sectionOne: localStorage.getItem("sectionOne"),
      sectionTwo: localStorage.getItem("sectionTwo"),
      sectionThree: localStorage.getItem("sectionThree"),
      sectionFour: localStorage.getItem("sectionFour"),
      teasers: localStorage.getItem("teasers"),
      topTitle: localStorage.getItem("topTitle"),
      twitter: localStorage.getItem("twitter"),
      youtube: localStorage.getItem("youtube"),
    };
    fetch(
      "https://mechanized-aesthetics.net/WEB-TEMPLATE-DATA/" +
      this.state.uid +
      "/" +
      this.state.uid +
      ".php",
      {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        //credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify(json),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        console.log("sending data to: " + this.state.uid);
        return response;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  logOut() {
    firebase
      .auth()
      .signOut()
      .then(
        function () {
          console.log("this user is signed out.");
        },
        function (error) {
          console.log("auth error: " + error);
        }
      );
    this.setState({
      editMode: false,
    });
    this.saveToLocalData();
    this.changeUserStatus();
  }

  submitLogin(e) {
    e.preventDefault();
    const email = document.querySelector("input[name='login-email']").value;
    const password = document.querySelector("input[name='login-password']")
      .value;
    auth.signInWithEmailAndPassword(email, password).then((cred) => {
      //console.log("ignInWithEmailAndPasswor cred: " + JSON.stringify(cred));
      document.querySelector("form#login-form").reset();
      //this.changeUserStatus();
    });
  }

  submitSignUp() {
    const signupForm = document.querySelector("#signup-form");
    /*signupForm.addEventListener("submit", e => {
      e.preventDefault();*/

    // get user info
    const email = document.querySelector("input[name='signup-email']").value;
    const password = document.querySelector("input[name='signup-password']")
      .value;

    // sign up the user
    auth.createUserWithEmailAndPassword(email, password).then((cred) => {
      console.log(cred.user);
      // close the signup modal & reset form
      /*const modal = document.querySelector('#modal-signup');
    M.Modal.getInstance(modal).close();
    signupForm.reset();*/
    });
    this.clearForms();
    /* });*/
  }

  componentDidMount() {
    this.changeUserStatus();
  }

  render() {
    if (this.state.topTitle) {
      document.title = this.state.topTitle;
    }

    document
      .querySelector("body")
      .setAttribute("data-design", this.state.design);

    localStorage.setItem("uid", this.state.uid);
    localStorage.setItem("email", this.state.email);
    localStorage.setItem("design", this.state.design);
    localStorage.setItem("teasers", JSON.stringify(this.state.teasers));
    localStorage.setItem("topTitle", this.state.topTitle);
    localStorage.setItem("address", this.state.address);
    localStorage.setItem("phone", this.state.phone);
    localStorage.setItem("rssFeed", this.state.rssFeed);

    return (
      <React.Fragment>
        <div>
          <SocialMediaTop
            uid={this.state.uid}
            topTitle={this.state.topTitle}
            address={this.state.address}
            phone={this.state.phone}
            email={this.state.email}
            editMode={this.state.editMode}
            updateThis={this.updateThis}
            teasers={this.state.teasers}
            scrollWindow={this.scrollWindow}
          />
          <Jumbotron
            uid={this.state.uid}
            editMode={this.state.editMode}
            teasers={this.state.teasers}
            scrollWindow={this.scrollWindow}
            editMainFocus={this.editMainFocus}
          />
          <main>
            {this.state.teasers.length > 0 ? (
              <React.Fragment>
                <MainFocus
                  editMode={this.state.editMode}
                  teasers={this.state.teasers}
                  editMainFocus={this.editMainFocus}
                  scrollWindow={this.scrollWindow}
                />
                <Carousel
                  uid={this.state.uid}
                  editMode={this.state.editMode}
                  sectionTitle={this.state.teasers[0].teaserTitle}
                  toggleAlert={this.toggleAlert}
                  alertMessage={this.state.alertMessage}
                  clearForms={this.clearForms}
                />
                <OptionSection
                  uid={this.state.uid}
                  sectionTitle={this.state.teasers[1].teaserTitle}
                  editMode={this.state.editMode}
                  sectionColor="whiteBg"
                  sectionNum="1"
                  toggleAlert={this.toggleAlert}
                  alertMessage={this.state.alertMessage}
                  clearForms={this.clearForms}
                />
                <ListSection
                  uid={this.state.uid}
                  sectionTitle={this.state.teasers[2].teaserTitle}
                  editMode={this.state.editMode}
                  clearForms={this.clearForms}
                  scrollWindow={this.scrollWindow}
                  toggleAlert={this.toggleAlert}
                  alertMessage={this.state.alertMessage}
                />
                {this.state.rssFeed !== "" ? (
                  <Blog rssFeed={this.state.rssFeed} />
                ) : null}
                <Contact
                  sectionTitle={this.state.teasers[3].teaserTitle}
                  topTitle={this.state.topTitle}
                  address={this.state.address}
                  phone={this.state.phone}
                  email={this.state.email}
                />{" "}
              </React.Fragment>
            ) : (
              <div className="loader center" />
            )}
          </main>
          <footer className="footer mt-auto py-3 bg-dark">
            <div className="container">
              <div className="row">
                <div className="col-md-3 txtCenter">
                  {this.state.loggedIn.length > 0 ? (
                    <span id="editMode" onClick={this.toggleEdit}>
                      <i className="far fa-edit" /> Edit Mode
                    </span>
                  ) : (
                    <a href="#loginBtn" onClick={this.adminLogin}>
                      <i className="fas fa-lock" /> Admin Login
                    </a>
                  )}

                  {this.state.editMode === true ? (
                    <Design
                      updateDesign={this.updateDesign}
                      design={this.state.design}
                    />
                  ) : null}
                </div>
                <div className="col-md-3 text-muted  txtCenter">
                  <i className="far fa-copyright" /> {this.state.copyrightYr}{" "}
                  Web-Presence LLC
                </div>
                <div className="col-md-3 text-muted  txtCenter">
                  <a
                    href="./termsAndConditions.html"
                    className="text-muted"
                    target="_blank"
                  >
                    Terms & Conditions
                  </a>
                </div>
                <div className="col-md-3 txtCenter">
                  <SocialMedia
                    uid={this.state.uid}
                    socialEdit="true"
                    editMode={this.state.editMode}
                    location="bottom"
                    bounceIn={this.bounceIn}
                    bounceOut={this.bounceIn}
                  />
                </div>
              </div>
            </div>
          </footer>
          <div className="container">
            <div className="row">
              {this.state.adminLogin === true &&
                this.state.loggedIn.length === 0 ? (
                <LoginForm submitLogin={this.submitLogin} />
              ) : null}

              <div className="col-md-12">
                {this.state.loggedIn.length > 0 ? (
                  <div className="form-group">
                    <button
                      className="btn btn-danger btn-block"
                      onClick={this.logOut}
                    >
                      Logout: <strong> {this.state.loggedIn} </strong>
                    </button>{" "}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default App;

//http://preview.themeforest.net/item/blessing-responsive-church-charity-site-template/full_screen_preview/3080448?_ga=2.141732471.412753305.1558452441-895630709.1558361047
//https://preview.themeforest.net/item/lifeline-ngo-and-charity-responsive-html-template/full_screen_preview/5662138?_ga=2.45785441.412753305.1558452441-895630709.1558361047

/******************************  sign in JSX

                  <div className="col-md-12">
                    <label>Sign up</label>
                    <div className="form-group">
                      <input
                        type="text"
                        placeholder="email"
                        className="form-control"
                        name="signup-email"
                      />
                      <input
                        type="password"
                        placeholder="password"
                        className="form-control"
                        name="signup-password"
                      />
                      <button
                        className="btn btn-primary btn-block"
                        onClick={this.submitSignUp}
                      >
                        Sign Up
                      </button>
                    </div>
                  </div>


*/
