import React, { Component } from "react";
import { auth, base } from "./base";

class SocialMedia extends Component {
  constructor() {
    super();
    this.state = {
      socialOptions: [
        "instagram",
        "facebook",
        "twitter",
        "linkedin",
        "youtube",
        "rss",
        "donate",
      ],
      editThisMedia: "fab fa-instagram",
      instagram: "",
      facebook: "",
      twitter: "",
      linkedin: "",
      youtube: "",
      rss: "",
      donate: "",
    };

    this.socialSelect = this.socialSelect.bind(this);
    this.updateSocial = this.updateSocial.bind(this);
    this.bounceIn = this.bounceIn.bind(this);
    this.bounceOut = this.bounceOut.bind(this);
  }

  componentWillMount() {
    auth.onAuthStateChanged((user) => {
      let theUser = this.props.uid;
      if (user) {
        theUser = user.uid;
      }

      base.syncState(`instagram/${theUser}`, {
        context: this,
        state: "instagram",
      });

      base.syncState(`facebook/${theUser}`, {
        context: this,
        state: "facebook",
      });

      base.syncState(`twitter/${theUser}`, {
        context: this,
        state: "twitter",
      });

      base.syncState(`linkedin/${theUser}`, {
        context: this,
        state: "linkedin",
      });

      base.syncState(`youtube/${theUser}`, {
        context: this,
        state: "youtube",
      });

      base.syncState(`rss/${theUser}`, {
        context: this,
        state: "rss",
      });

      base.syncState(`donate/${theUser}`, {
        context: this,
        state: "donate",
      });
    });
  }

  socialSelect() {
    let whichsocialMedia = document.getElementById("socialoptions").value;
    this.setState({
      editThisMedia: whichsocialMedia,
    });
  }

  updateSocial(changeThis) {
    let theUpdate = document.querySelector("[name='" + changeThis + "']").value;
    this.setState({
      [changeThis]: theUpdate,
    });
    document.querySelector("[name='" + changeThis + "']").value = "";
  }

  bounceIn(social, location) {
    console.log("social: " + social);
    document
      .querySelector("[data-social='" + social + "'] i.animated." + location)
      .classList.add("tada");
  }

  bounceOut(social, location) {
    document
      .querySelector("[data-social='" + social + "']  i.animated." + location)
      .classList.remove("tada");
  }

  render() {
    const socialState = [
      this.state.instagram.toString(),
      this.state.facebook.toString(),
      this.state.twitter.toString(),
      this.state.linkedin.toString(),
      this.state.youtube.toString(),
      this.state.rss.toString(),
      this.state.donate.toString(),
    ];

    localStorage.setItem("instagram", this.state.instagram.toString());
    localStorage.setItem("facebook", this.state.facebook.toString());
    localStorage.setItem("twitter", this.state.twitter.toString());
    localStorage.setItem("linkedin", this.state.linkedin.toString());
    localStorage.setItem("youtube", this.state.youtube.toString());
    localStorage.setItem("donate", this.state.donate.toString());

    const socClasses = [
      "fab fa-instagram",
      "fab fa-facebook",
      "fab fa-twitter",
      "fab fa-linkedin",
      "fab fa-youtube",
      "fas fa-rss",
      "fas fa-donate",
    ];

    return (
      <React.Fragment>
        {this.props.editMode && this.props.socialEdit === "true" ? (
          <nav className="my-2 my-md-0 mr-md-3">
            {this.state.instagram !== "" &&
            typeof this.state.instagram === "string" ? (
              <a
                className="p-2"
                href={this.state.instagram}
                target="_blank"
                alt="instagram"
                data-social="instagram"
              >
                <i className="fab fa-instagram" />
              </a>
            ) : null}
            {this.state.facebook !== "" ? (
              <a
                className="p-2"
                href={this.state.facebook}
                target="_blank"
                alt="facebook"
                data-social="facebook"
              >
                <i className="fab fa-facebook" />
              </a>
            ) : null}
            {this.state.twitter !== "" ? (
              <a
                className="p-2"
                href={this.state.twitter}
                target="_blank"
                alt="twitter"
                data-social="twitter"
              >
                <i className="fab fa-twitter" />
              </a>
            ) : null}
            {this.state.linkedin !== "" ? (
              <a
                className="p-2"
                href={this.state.linkedin}
                target="_blank"
                alt="linkedin"
                data-social="linkedin"
              >
                <i className="fab fa-linkedin" />
              </a>
            ) : null}
            {this.state.youtube !== "" ? (
              <a
                className="p-2"
                href={this.state.youtube}
                target="_blank"
                alt="youtube"
                data-social="youtube"
              >
                <i className="fab fa-youtube" />
              </a>
            ) : null}

            {this.state.donate !== "" ? (
              <a
                className="p-2"
                href={this.state.donate}
                target="_blank"
                alt="donate"
                data-social="donate"
              >
                <i className="fas fa-donate" />
              </a>
            ) : null}

            <ul className="noStyle">
              <li>
                <label>Select link to update</label>
                <select
                  onChange={this.socialSelect}
                  id="socialoptions"
                  className="form-control"
                >
                  {this.state.socialOptions.length > 0
                    ? this.state.socialOptions.map((media, i) => {
                        return (
                          <option value={socClasses[i]} key={i}>
                            {media}
                          </option>
                        );
                      })
                    : "no data"}
                </select>
              </li>
              <li>
                <label>Update Social Media Addresses</label>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    name={this.state.editThisMedia.substring(7)}
                    placeholder={this.state.editThisMedia.substring(7)}
                    data-regex="any"
                    maxLength="500"
                  />
                  <span className="input-group-btn">
                    <button
                      className="btn btn-secondary"
                      onClick={this.updateSocial.bind(
                        this,
                        this.state.editThisMedia.substring(7)
                      )}
                    >
                      {" "}
                      Update <i className={this.state.editThisMedia} />
                    </button>
                  </span>
                </div>
              </li>
            </ul>
          </nav>
        ) : (
          <nav className="my-2 my-md-0 mr-md-3">
            {this.state.instagram !== "" ? (
              <a
                className="p-2"
                href={this.state.instagram}
                target="_blank"
                alt="instagram"
                title="instagram"
                data-social="instagram"
              >
                <i
                  className={"fab fa-instagram animated " + this.props.location}
                  onMouseOver={this.bounceIn.bind(
                    this,
                    "instagram",
                    this.props.location
                  )}
                  onMouseOut={this.bounceOut.bind(
                    this,
                    "instagram",
                    this.props.location
                  )}
                />
              </a>
            ) : null}
            {this.state.facebook !== "" ? (
              <a
                className="p-2"
                href={this.state.facebook}
                target="_blank"
                alt="facebook"
                title="facebook"
                data-social="facebook"
              >
                <i
                  className={"fab fa-facebook animated " + this.props.location}
                  onMouseOver={this.bounceIn.bind(
                    this,
                    "facebook",
                    this.props.location
                  )}
                  onMouseOut={this.bounceOut.bind(
                    this,
                    "facebook",
                    this.props.location
                  )}
                />
              </a>
            ) : null}
            {this.state.twitter !== "" ? (
              <a
                className="p-2"
                href={this.state.twitter}
                target="_blank"
                alt="twitter"
                title="twitter"
                data-social="twitter"
              >
                <i
                  className={"fab fa-twitter animated " + this.props.location}
                  onMouseOver={this.bounceIn.bind(
                    this,
                    "twitter",
                    this.props.location
                  )}
                  onMouseOut={this.bounceOut.bind(
                    this,
                    "twitter",
                    this.props.location
                  )}
                />
              </a>
            ) : null}
            {this.state.linkedin !== "" ? (
              <a
                className="p-2"
                href={this.state.linkedin}
                target="_blank"
                alt="linkedin"
                title="linkedin"
                data-social="linkedin"
              >
                <i
                  className={"fab fa-linkedin animated " + this.props.location}
                  onMouseOver={this.bounceIn.bind(
                    this,
                    "linkedin",
                    this.props.location
                  )}
                  onMouseOut={this.bounceOut.bind(
                    this,
                    "linkedin",
                    this.props.location
                  )}
                />
              </a>
            ) : null}
            {this.state.youtube !== "" ? (
              <a
                className="p-2"
                href={this.state.youtube}
                target="_blank"
                alt="youtube"
                title="youtube"
                data-social="youtube"
              >
                <i
                  className={"fab fa-youtube animated " + this.props.location}
                  onMouseOver={this.bounceIn.bind(
                    this,
                    "youtube",
                    this.props.location
                  )}
                  onMouseOut={this.bounceOut.bind(
                    this,
                    "youtube",
                    this.props.location
                  )}
                />
              </a>
            ) : null}

            {this.state.donate !== "" ? (
              <a
                className="p-2"
                href={this.state.donate}
                target="_blank"
                alt="donate"
                title="donate"
                data-social="donate"
              >
                <i
                  className={"fas fa-donate animated " + this.props.location}
                  onMouseOver={this.bounceIn.bind(
                    this,
                    "donate",
                    this.props.location
                  )}
                  onMouseOut={this.bounceOut.bind(
                    this,
                    "donate",
                    this.props.location
                  )}
                />
              </a>
            ) : null}
          </nav>
        )}
      </React.Fragment>
    );
  }
}

export default SocialMedia;
