import React, { Component } from "react";

class RemoveOption extends Component {
  render() {
    return (
      <div className="editMode">
        <button
          className="btn btn-primary btn-block"
          onClick={this.props.toggleAlert.bind(
            this,
            "section-" + this.props.section + "-column-" + this.props.i
          )}
        >
          <i className="fas fa-minus-square" /> Remove Column {this.props.i}
        </button>

        <div
          className={
            this.props.alertMessage ===
            "section-" + this.props.section + "-column-" + this.props.i
              ? "alert alert-danger"
              : "alert alert-danger hide"
          }
          role="alert"
        >
          Are you sure you want to delete Column {this.props.i} {"? "}
          <button
            onClick={this.props.removeColum.bind(
              this,
              this.props.section,
              this.props.i
            )}
            className="btn btn-danger"
          >
            YES
          </button>{" "}
          <button
            onClick={this.props.toggleAlert.bind(this, "")}
            className="btn btn-secondary"
          >
            NO
          </button>
        </div>
      </div>
    );
  }
}

export default RemoveOption;
