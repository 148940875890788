import React, { Component } from "react";

class LoginForm extends Component {
  render() {
    return (
      <div className="col-md-12">
        <h3>Login</h3>
        <div className="form-group">
          <form id="login-form" onSubmit={this.props.submitLogin}>
            <input
              type="text"
              placeholder="email"
              className="form-control"
              name="login-email"
            />
            <input
              type="password"
              placeholder="password"
              className="form-control"
              name="login-password"
            />
            <button id="loginBtn" className="btn btn-primary btn-block">
              Login
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default LoginForm;
