import React, { Component } from "react";
import { auth, base } from "./base";
import OptionEditor from "./OptionEditor";
import MultiColSection from "./MultiColSection";

class OptionSection extends Component {
  constructor() {
    super();
    this.state = {
      editSection: "sectionOne",
      sectionOne: [],
      sectionTwo: [],
      sectionThree: [],
      sectionFour: [],
      tempNames: [],
      tempContent: [],
    };
    this.updateSection = this.updateSection.bind(this);
    this.buildPreObj = this.buildPreObj.bind(this);
    this.removeColum = this.removeColum.bind(this);
    this.bounceScroll = this.bounceScroll.bind(this);
    this.selectSection = this.selectSection.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.bounceScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.bounceScroll);
  }

  bounceScroll() {
    const wHeight = window.innerHeight;

    if (this.state.sectionOne.length > 0) {
      let sectionOne = document
        .querySelector("div[data-section='sectionOne']")
        .getBoundingClientRect().top; // Y
      if (sectionOne < wHeight - 10) {
        document
          .querySelector("div[data-section='sectionOne']")
          .classList.add("fadeIn");
      }
    }

    if (this.state.sectionTwo.length > 0) {
      let sectionTwo = document
        .querySelector("div[data-section='sectionTwo']")
        .getBoundingClientRect().top; // Y
      if (sectionTwo < wHeight - 300) {
        document
          .querySelector("div[data-section='sectionTwo']")
          .classList.add("fadeIn");
      }
    }
    if (this.state.sectionThree.length > 0) {
      let sectionThree = document
        .querySelector("div[data-section='sectionThree']")
        .getBoundingClientRect().top; // Y
      if (sectionThree < wHeight - 300) {
        document
          .querySelector("div[data-section='sectionThree']")
          .classList.add("fadeIn");
      }
    }
    if (this.state.sectionFour.length > 0) {
      let sectionFour = document
        .querySelector("div[data-section='sectionFour']")
        .getBoundingClientRect().top; // Y
      if (sectionFour < wHeight - 300) {
        document
          .querySelector("div[data-section='sectionFour']")
          .classList.add("fadeIn");
      }
    }
  }

  removeColum(whichSection, whichItem) {
    let tempData = [];
    switch (whichSection) {
      case "sectionOne":
        for (let i = 0; i < this.state.sectionOne.length; i++) {
          if (i !== whichItem) {
            tempData.push(this.state.sectionOne[i]);
          }
        }
        break;

      case "sectionTwo":
        for (let i = 0; i < this.state.sectionTwo.length; i++) {
          if (i !== whichItem) {
            tempData.push(this.state.sectionTwo[i]);
          }
        }
        break;
      case "sectionThree":
        for (let i = 0; i < this.state.sectionThree.length; i++) {
          if (i !== whichItem) {
            tempData.push(this.state.sectionThree[i]);
          }
        }
        break;
      case "sectionFour":
        for (let i = 0; i < this.state.sectionFour.length; i++) {
          if (i !== whichItem) {
            tempData.push(this.state.sectionFour[i]);
          }
        }
        break;
    }

    this.setState({
      [whichSection]: tempData,
    });
  }

  buildPreObj(whatContent) {
    let buildNames = this.state.tempNames;
    let buildContent = this.state.tempContent;
    let newName = document.querySelector(
      "#" + whatContent + " input[name='name']"
    ).value;
    let newContent = document.querySelector(
      "#" + whatContent + " textarea[name='content']"
    ).value;
    if (
      newName === "" ||
      newContent === "" ||
      newName === null ||
      newContent === null ||
      newName === undefined ||
      newContent === undefined
    ) {
      alert("To submit, the Name and Content fields need to have something.");
      return false;
    }
    buildNames.push(newName);
    buildContent.push(newContent);
    this.setState({
      tempNames: buildNames,
      tempContent: buildContent,
    });
    this.props.clearForms();
  }

  selectSection() {
    let whichSection = document.querySelector("#editSection").value;

    this.setState({
      editSection: whichSection,
    });
  }

  updateSection(whichSection, whichCol, whichType) {
    if (document.querySelectorAll("input.error, .error.ql-editor")) {
      [].forEach.call(
        document.querySelectorAll("input.error, .error.ql-editor"),
        function (e) {
          e.classList.remove("error");
        }
      );
    }
    let sectionLength = 0;
    let tempData = [];

    switch (whichType) {
      case "text":
        let title = document.querySelector(
          "[data-option='text-" +
            whichSection +
            "-" +
            whichCol +
            "'] [name='title']"
        ).value;
        if (!title) {
          document
            .querySelector(
              "[data-option='text-" +
                whichSection +
                "-" +
                whichCol +
                "'] [name='title']"
            )
            .classList.add("error");
          return false;
        }

        let text = document.querySelector(
          "[data-option='text-" +
            whichSection +
            "-" +
            whichCol +
            "'] .ql-editor"
        ).innerHTML;

        if (text === "<p><br></p>") {
          document
            .querySelector(
              "[data-option='text-" +
                whichSection +
                "-" +
                whichCol +
                "'] .ql-editor"
            )
            .classList.add("error");
          return false;
        }

        tempData = "<h2>" + title + "</h2><p>" + text + "</p>";
        break;
      case "image":
        let imgTitle = document.querySelector(
          "[data-option='img-" +
            whichSection +
            "-" +
            whichCol +
            "']  [name='imageTitle']"
        ).value;

        if (!imgTitle) {
          document
            .querySelector(
              "[data-option='img-" +
                whichSection +
                "-" +
                whichCol +
                "']  [name='imageTitle']"
            )
            .classList.add("error");
          return false;
        }

        let imgSrc = document.querySelector(
          "[data-option='img-" +
            whichSection +
            "-" +
            whichCol +
            "']  [name='imageAddress']"
        ).value;

        if (!imgSrc) {
          document
            .querySelector(
              "[data-option='img-" +
                whichSection +
                "-" +
                whichCol +
                "']  [name='imageAddress']"
            )
            .classList.add("error");
          return false;
        }

        tempData =
          "<h2>" +
          imgTitle +
          "</h2><img src='" +
          imgSrc +
          "' class='img-fluid centerAuto img-thumbnail mediaComponent' data-type='image'  alt='" +
          imgTitle +
          "' />";
        break;
      case "video":
        let videoTitle = document.querySelector(
          "[data-option='video-" +
            whichSection +
            "-" +
            whichCol +
            "']  [name='videoTitle']"
        ).value;

        if (!videoTitle) {
          document
            .querySelector(
              "[data-option='video-" +
                whichSection +
                "-" +
                whichCol +
                "']  [name='videoTitle']"
            )
            .classList.add("error");
          return false;
        }

        let videoId = document.querySelector(
          "[data-option='video-" +
            whichSection +
            "-" +
            whichCol +
            "']  [name='video']"
        ).value;
        if (!videoId) {
          document
            .querySelector(
              "[data-option='video-" +
                whichSection +
                "-" +
                whichCol +
                "']  [name='video']"
            )
            .classList.add("error");
          return false;
        }

        tempData =
          "<h2>" +
          videoTitle +
          "</h2><iframe class='img-fluid centerAuto img-thumbnail mediaComponent' data-type='video'  src='https://www.youtube.com/embed/" +
          videoId +
          "' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>";
        break;

      case "map":
        let mapTitle = document.querySelector(
          "[data-option='map-" +
            whichSection +
            "-" +
            whichCol +
            "'] [name='mapTitle']"
        ).value;

        if (!mapTitle) {
          document
            .querySelector(
              "[data-option='map-" +
                whichSection +
                "-" +
                whichCol +
                "'] [name='mapTitle']"
            )
            .classList.add("error");
          return false;
        }

        let address = document.querySelector(
          "[data-option='map-" +
            whichSection +
            "-" +
            whichCol +
            "'] [name='address']"
        ).value;

        if (!address) {
          document
            .querySelector(
              "[data-option='map-" +
                whichSection +
                "-" +
                whichCol +
                "'] [name='address']"
            )
            .classList.add("error");
          return false;
        }

        tempData =
          "<h2>" +
          mapTitle +
          "</h2><iframe class='img-fluid centerAuto img-thumbnail mediaComponent' data-type='map' frameborder='0'  src='https://www.google.com/maps/embed/v1/place?key=AIzaSyBxvGBPN_lRhoYskabk_lZ5FAo4GIowU6I&q=" +
          address +
          "' allowfullscreen></iframe>";

        break;

      /////////////////////////////START CALENDAR

      case "calendar":
        let calendarTitle = document.querySelector(
          "[data-option='calendar-" +
            whichSection +
            "-" +
            whichCol +
            "'] [name='calendarTitle']"
        ).value;

        if (!calendarTitle) {
          document
            .querySelector(
              "[data-option='calendar-" +
                whichSection +
                "-" +
                whichCol +
                "'] [name='calendarTitle']"
            )
            .classList.add("error");
          return false;
        }

        let srcVal = document.querySelector(
          "[data-option='calendar-" +
            whichSection +
            "-" +
            whichCol +
            "'] [name='calendar']"
        ).value;

        if (!srcVal) {
          document
            .querySelector(
              "[data-option='calendar-" +
                whichSection +
                "-" +
                whichCol +
                "'] [name='calendar']"
            )
            .classList.add("error");
          return false;
        }

        tempData =
          "<h2>" +
          calendarTitle +
          "</h2><iframe src='" +
          srcVal +
          "' frameborder='0' scrolling='no' style='border: 0px; width: 100%; min-height: 600px;'></iframe>";

        break;
    }

    switch (whichSection) {
      case "sectionOne":
        if (whichCol === "newContent") {
          let tempSectionOne = [];
          if (this.state.sectionOne.length > 0) {
            tempSectionOne = this.state.sectionOne;
          }
          tempSectionOne.push(tempData);
          this.setState({
            sectionOne: tempSectionOne,
          });
        } else {
          let replaceTemp = { ...this.state.sectionOne };
          replaceTemp[whichCol] = tempData;
          this.setState({
            sectionOne: replaceTemp,
          });
        }

        break;
      case "sectionTwo":
        if (whichCol === "newContent") {
          let tempSectionTwo = [];
          if (this.state.sectionTwo.length > 0) {
            tempSectionTwo = this.state.sectionTwo;
          }
          tempSectionTwo.push(tempData);
          this.setState({
            sectionTwo: tempSectionTwo,
          });
        } else {
          let replaceTemp = { ...this.state.sectionTwo };
          replaceTemp[whichCol] = tempData;
          this.setState({
            sectionTwo: replaceTemp,
          });
        }
        break;
      case "sectionThree":
        if (whichCol === "newContent") {
          let tempSectionThree = [];
          if (this.state.sectionThree.length > 0) {
            tempSectionThree = this.state.sectionThree;
          }
          tempSectionThree.push(tempData);
          this.setState({
            sectionThree: tempSectionThree,
          });
        } else {
          let replaceTemp = { ...this.state.sectionThree };
          replaceTemp[whichCol] = tempData;
          this.setState({
            sectionThree: replaceTemp,
          });
        }
        break;
      case "sectionFour":
        if (whichCol === "newContent") {
          let tempSectionFour = [];
          if (this.state.sectionFour.length > 0) {
            tempSectionFour = this.state.sectionFour;
          }
          tempSectionFour.push(tempData);
          this.setState({
            sectionFour: tempSectionFour,
          });
        } else {
          let replaceTemp = { ...this.state.sectionFour };
          replaceTemp[whichCol] = tempData;
          this.setState({
            sectionFour: replaceTemp,
          });
        }
        break;
    }

    this.setState({
      newSubmission: false,
    });

    this.props.clearForms();
  }

  componentWillMount() {
    let theUser = "";
    let guestUser = this.props.uid;

    auth.onAuthStateChanged((user) => {
      if (user) {
        theUser = user.uid;
      } else {
        theUser = guestUser;
      }
      base.syncState(`sectionOne/${theUser}`, {
        context: this,
        state: "sectionOne",
      });
      base.syncState(`sectionTwo/${theUser}`, {
        context: this,
        state: "sectionTwo",
      });

      base.syncState(`sectionThree/${theUser}`, {
        context: this,
        state: "sectionThree",
      });

      base.syncState(`sectionFour/${theUser}`, {
        context: this,
        state: "sectionFour",
      });
    });
  }

  render() {
    let sectionOneColNum = "12";
    switch (this.state.sectionOne.length) {
      case 2:
        sectionOneColNum = "6";
        break;
      case 3:
        sectionOneColNum = "4";
        break;
      case 4:
        sectionOneColNum = "3";
        break;
    }

    let sectionTwoColNum = "12";
    switch (this.state.sectionTwo.length) {
      case 2:
        sectionTwoColNum = "6";
        break;
      case 3:
        sectionTwoColNum = "4";
        break;
      case 4:
        sectionTwoColNum = "3";
        break;
    }
    let sectionThreeColNum = "12";
    switch (this.state.sectionThree.length) {
      case 2:
        sectionThreeColNum = "6";
        break;
      case 3:
        sectionThreeColNum = "4";
        break;
      case 4:
        sectionThreeColNum = "3";
        break;
    }
    let sectionFourColNum = "12";
    switch (this.state.sectionFour.length) {
      case 2:
        sectionFourColNum = "6";
        break;
      case 3:
        sectionFourColNum = "4";
        break;
      case 4:
        sectionFourColNum = "3";
        break;
    }

    localStorage.setItem("sectionOne", JSON.stringify(this.state.sectionOne));
    localStorage.setItem("sectionTwo", JSON.stringify(this.state.sectionTwo));
    localStorage.setItem(
      "sectionThree",
      JSON.stringify(this.state.sectionThree)
    );
    localStorage.setItem("sectionFour", JSON.stringify(this.state.sectionFour));

    return (
      <React.Fragment>
        {this.state.sectionOne.length > 0 ? (
          <MultiColSection
            sectionTitle={this.props.sectionTitle}
            removeColum={this.removeColum}
            section={"sectionOne"}
            sectionData={this.state.sectionOne}
            toggleAlert={this.props.toggleAlert}
            alertMessage={this.props.alertMessage}
            newContent="false"
            updateSection={this.updateSection}
            buildPreObj={this.buildPreObj}
            editMode={this.props.editMode}
            theClass={"col-md-" + sectionOneColNum}
          />
        ) : null}

        {this.state.sectionTwo.length > 0 ? (
          <MultiColSection
            sectionTitle={this.props.sectionTitle}
            removeColum={this.removeColum}
            section={"sectionTwo"}
            sectionData={this.state.sectionTwo}
            toggleAlert={this.props.toggleAlert}
            alertMessage={this.props.alertMessage}
            newContent="false"
            updateSection={this.updateSection}
            buildPreObj={this.buildPreObj}
            editMode={this.props.editMode}
            theClass={"col-md-" + sectionTwoColNum}
          />
        ) : null}

        {this.state.sectionThree.length > 0 ? (
          <MultiColSection
            sectionTitle={this.props.sectionTitle}
            removeColum={this.removeColum}
            section={"sectionThree"}
            sectionData={this.state.sectionThree}
            toggleAlert={this.props.toggleAlert}
            alertMessage={this.props.alertMessage}
            newContent="false"
            updateSection={this.updateSection}
            buildPreObj={this.buildPreObj}
            editMode={this.props.editMode}
            theClass={"col-md-" + sectionThreeColNum}
          />
        ) : null}

        {this.state.sectionFour.length > 0 ? (
          <MultiColSection
            sectionTitle={this.props.sectionTitle}
            removeColum={this.removeColum}
            section={"sectionFour"}
            sectionData={this.state.sectionFour}
            toggleAlert={this.props.toggleAlert}
            alertMessage={this.props.alertMessage}
            newContent="false"
            updateSection={this.updateSection}
            buildPreObj={this.buildPreObj}
            editMode={this.props.editMode}
            theClass={"col-md-" + sectionFourColNum}
          />
        ) : null}

        {this.props.editMode ? (
          <div className="container">
            <div className="row">
              <div className="col-md-12 editMode">
                <h3>Add New Section</h3>
                <select
                  className="form-control"
                  id="editSection"
                  onChange={this.selectSection}
                >
                  <option value="sectionOne">Section selected: 1 </option>
                  <option value="sectionTwo">Section selected: 2 </option>
                  <option value="sectionThree">Section selected: 3 </option>
                  <option value="sectionFour">Section selected: 4 </option>
                </select>

                <OptionEditor
                  section={this.state.editSection}
                  column="newContent"
                  newContent="true"
                  updateSection={this.updateSection}
                  buildPreObj={this.buildPreObj}
                />
              </div>
            </div>
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

export default OptionSection;
