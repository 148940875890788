import Rebase from "re-base";
import firebase from "firebase";

const config = {
  apiKey: "AIzaSyDrSDa0P-cnZXeOeAr75FxFRG3gB3W22YQ",
  authDomain: "web-templates-6a6f1.firebaseapp.com",
  databaseURL: "https://web-templates-6a6f1.firebaseio.com",
  projectId: "web-templates-6a6f1",
  storageBucket: "",
  messagingSenderId: "203277159305",
  appId: "1:203277159305:web:ff969af9f72423f6"
};

const app = firebase.initializeApp(config);
const base = Rebase.createClass(app.database());
const auth = firebase.auth();
const functions = firebase.functions();

export { app, base, auth, functions };
