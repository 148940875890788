import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import OptionEditor from "./OptionEditor";
import Divider from "./Divider";
import RemoveOption from "./RemoveOption";

class MultiColSection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="album py-5 animated" data-section={this.props.section}>
        <div className="container">
          {this.props.section === "sectionOne" ? (
            <div className="row">
              <div className="col-md-12">
                <h2 className="txtCenter" data-target={this.props.sectionTitle}>
                  {this.props.sectionTitle}
                </h2>
                <hr />
              </div>
            </div>
          ) : null}
          <div className="row equalize1">
            {this.props.sectionData.map((content, i) => {
              return (
                <div data-equalize={i} className={this.props.theClass} key={i}>
                  {this.props.editMode === true ? (
                    <React.Fragment>
                      <RemoveOption
                        removeColum={this.props.removeColum}
                        section={this.props.section}
                        i={i}
                        toggleAlert={this.props.toggleAlert}
                        alertMessage={this.props.alertMessage}
                      />
                      <div className="editMode">
                        {this.props.alertMessage !==
                        this.props.section + "-column-" + i ? (
                          <h3
                            onClick={this.props.toggleAlert.bind(
                              this,
                              this.props.section + "-column-" + i
                            )}
                          >
                            <i class="fas fa-pencil-alt" /> Edit Column Content{" "}
                            {i + " "} <i class="fas fa-plus-square"></i>
                          </h3>
                        ) : null}
                        {this.props.alertMessage ===
                        this.props.section + "-column-" + i ? (
                          <React.Fragment>
                            <h3 onClick={this.props.toggleAlert.bind(this, "")}>
                              <i class="fas fa-pencil-alt" /> Edit Column
                              Content {i + " "}{" "}
                              <i class="fas fa-minus-square"></i>
                            </h3>
                            <OptionEditor
                              section={this.props.section}
                              column={i}
                              newContent="false"
                              updateSection={this.props.updateSection}
                              buildPreObj={this.props.buildPreObj}
                              content={content}
                            />
                          </React.Fragment>
                        ) : null}
                      </div>
                    </React.Fragment>
                  ) : null}

                  {ReactHtmlParser(content)}
                </div>
              );
            })}
          </div>
          <Divider />
        </div>
      </div>
    );
  }
}

export default MultiColSection;
