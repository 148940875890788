import React, { Component } from "react";
import ReactQuill from "react-quill";

class OptionEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editType: "text",
      contentOptions: ["text", "image", "video", "map", "calendar"],
      text: "",
    };

    this.contentType = this.contentType.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(value) {
    this.setState({ text: value });
  }

  contentType(section, column) {
    let whichType = document.querySelector(
      "select.column" + column + "[data-section='" + section + "']"
    ).value;

    this.setState({
      editType: whichType,
    });
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          <select
            data-section={this.props.section}
            className={"form-control column" + this.props.column}
            onChange={this.contentType.bind(
              this,
              this.props.section,
              this.props.column
            )}
          >
            {this.state.contentOptions
              ? this.state.contentOptions.map((option, i) => {
                  return (
                    <option key={i} value={option}>
                      {"Content type selected: " + option}
                    </option>
                  );
                })
              : null}
          </select>
        </div>

        {this.state.editType === "text" ? (
          <div
            className="col-md-12"
            data-option={"text-" + this.props.section + "-" + this.props.column}
          >
            <input
              type="text"
              className="form-control"
              name="title"
              placeholder="Title"
              data-content={this.props.section}
            />

            <div data-content={this.props.section}>
              <ReactQuill
                value={this.state.text}
                onChange={this.handleChange}
              />
            </div>

            <button
              className="btn btn-primary btn-block"
              onClick={this.props.updateSection.bind(
                this,
                this.props.section,
                this.props.column,
                this.state.editType
              )}
            >
              {this.props.newContent === "true" ? "Submit" : "Update content"}
            </button>
          </div>
        ) : null}

        {this.state.editType === "image" ? (
          <div
            className="col-md-12"
            data-option={"img-" + this.props.section + "-" + this.props.column}
          >
            <input
              type="text"
              className="form-control"
              name="imageTitle"
              placeholder="Image Title (alt tag)"
              data-content={this.props.section}
            />
            <input
              type="text"
              className="form-control"
              name="imageAddress"
              placeholder="Image URL/Address"
              data-content={this.props.section}
            />
            <button
              className="btn btn-primary btn-block"
              onClick={this.props.updateSection.bind(
                this,
                this.props.section,
                this.props.column,
                this.state.editType
              )}
            >
              {this.props.newContent === "true" ? "Submit" : "Update content"}
            </button>
          </div>
        ) : null}

        {this.state.editType === "video" ? (
          <div
            className="col-md-12"
            data-option={
              "video-" + this.props.section + "-" + this.props.column
            }
          >
            <input
              type="text"
              className="form-control"
              name="videoTitle"
              placeholder="Video Title"
              maxLength="50"
              data-content={this.props.section}
            />
            <input
              type="text"
              className="form-control"
              name="video"
              placeholder="YouTube video ID"
              maxLength="11"
              data-content={this.props.section}
            />

            <button
              className="btn btn-primary btn-block"
              onClick={this.props.updateSection.bind(
                this,
                this.props.section,
                this.props.column,
                this.state.editType
              )}
            >
              {this.props.newContent === "true" ? "Submit" : "Update content"}
            </button>
          </div>
        ) : null}

        {this.state.editType === "map" ? (
          <div
            className="col-md-12"
            data-option={"map-" + this.props.section + "-" + this.props.column}
          >
            <input
              type="text"
              className="form-control"
              name="mapTitle"
              placeholder="Map Title"
              maxLength="50"
              data-content={this.props.section}
            />
            <input
              type="text"
              className="form-control"
              name="address"
              placeholder="Address"
              maxLength="50"
              data-content={this.props.section}
            />

            <button
              className="btn btn-primary btn-block"
              onClick={this.props.updateSection.bind(
                this,
                this.props.section,
                this.props.column,
                this.state.editType
              )}
            >
              {this.props.newContent === "true" ? "Submit" : "Update content"}
            </button>
          </div>
        ) : null}

        {this.state.editType === "calendar" ? (
          <div
            className="col-md-12"
            data-option={
              "calendar-" + this.props.section + "-" + this.props.column
            }
          >
            <input
              type="text"
              className="form-control"
              name="calendarTitle"
              placeholder="Calendar Title"
              maxLength="50"
              data-content={this.props.section}
            />
            <input
              type="text"
              className="form-control"
              name="calendar"
              placeholder="Calendar src value"
              maxLength="450"
              data-content={this.props.section}
            />

            <button
              className="btn btn-primary btn-block"
              onClick={this.props.updateSection.bind(
                this,
                this.props.section,
                this.props.column,
                this.state.editType
              )}
            >
              {this.props.newContent === "true" ? "Submit" : "Update content"}
            </button>
          </div>
        ) : null}
      </div>
    );
  }
}

export default OptionEditor;
