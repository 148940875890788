import React, { Component } from "react";
import Auth from "./Auth";
import firebase from "firebase";
import { auth, base } from "./base";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import AddEditEvent from "./AddEditEvent";
import Validate from "./Validate";

class ListSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeEvent: 0,
      events: [],
      newEvent: true,
    };

    this.showEvent = this.showEvent.bind(this);
    this.submitEvent = this.submitEvent.bind(this);
    this.beatScroll = this.beatScroll.bind(this);
    this.addEdit = this.addEdit.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.beatScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.beatScroll);
  }

  clearErrors() {
    if (document.querySelectorAll(".error")) {
      [].forEach.call(document.querySelectorAll(".error"), function (e) {
        e.classList.remove("error");
      });
    }
  }

  addEdit(addEdit) {
    if (addEdit === true) {
      this.setState({
        newEvent: true,
      });

      [].forEach.call(document.querySelectorAll("input"), function (e) {
        e.value = "";
      });
      if (document.querySelector("#eventDetails .ql-editor")) {
        document.querySelector("#eventDetails .ql-editor").innerHTML = "";
      }
    } else {
      this.setState({
        newEvent: false,
      });
    }
    this.clearErrors();
  }

  handleChange(value) {
    this.setState({ text: value });
  }

  beatScroll() {
    const wHeight = window.innerHeight;
    let sectionOne = document
      .querySelector("[data-section='listSection']")
      .getBoundingClientRect().top;

    if (sectionOne < wHeight - 10) {
      const betNum = [1, 2, 3];
      for (let i = 0; i < betNum.length; i++) {
        if (document.querySelector("div[data-beat='" + (i + 1) + "'].hide")) {
          document
            .querySelector("div[data-beat='" + (i + 1) + "']")
            .classList.remove("hide");

          document
            .querySelector("div[data-beat='" + (i + 1) + "']")
            .classList.add("fadeIn");
        }
      }
    }
  }

  componentWillMount() {
    auth.onAuthStateChanged((user) => {
      let theUser = this.props.uid;
      if (user) {
        theUser = user.uid;
      }
      base.syncState(`events/${theUser}`, {
        context: this,
        state: "events",
      });
    });
  }

  showEvent(whichEvent) {
    this.setState({
      activeEvent: whichEvent,
    });
  }
  removeListItem(whichItem) {
    let tempEvents = this.state.events;
    let buildNew = [];
    for (let i = 0; i < tempEvents.length; i++) {
      if (i !== whichItem) {
        buildNew.push(tempEvents[i]);
      }
    }
    this.setState({
      events: buildNew,
      activeEvent: 0,
    });
  }

  submitEvent(newEvent) {
    this.clearErrors();
    Validate(["eventTitle", "eventDateTime", "eventAddress", "eventContact"]);
    let tempEvents = [];
    if (this.state.events.length > 0) {
      tempEvents = this.state.events;
    }
    let title = document.querySelector("[name='eventTitle']").value;
    let dateTime = document.querySelector("[name='eventDateTime']").value;
    let address = document.querySelector("[name='eventAddress']").value;
    let contact = document.querySelector("[name='eventContact']").value;
    let details = document.querySelector("[name='eventDetails']").value;
    if (details.length === 0) {
      document.querySelector("#eventDetails .ql-editor").classList.add("error");
    }

    if (document.querySelector(".error")) {
      return false;
    } else {
      if (this.state.newEvent === true) {
        tempEvents.push({
          title: title,
          dateTime: dateTime,
          address: address,
          contact: contact,
          details: details,
        });
      } else {
        tempEvents[this.state.activeEvent] = {
          title: title,
          dateTime: dateTime,
          address: address,
          contact: contact,
          details: details,
        };
      }

      this.setState({
        events: tempEvents,
      });

      this.props.clearForms();
      if (document.querySelector("#eventDetails .ql-editor")) {
        document.querySelector("#eventDetails .ql-editor").innerHTML = "";
      }
    }
  }
  render() {
    let activeEvent = this.state.activeEvent;
    localStorage.setItem("events", JSON.stringify(this.state.events));
    return (
      <React.Fragment>
        <div
          style={
            this.state.events.length > 0 || this.state.editMode === true
              ? { display: "block" }
              : { display: "none" }
          }
          className="album py-5"
          data-section="listSection"
        >
          {this.state.events.length > 0 ? (
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h2
                    className="txtCenter"
                    data-target={this.props.sectionTitle}
                  >
                    {this.props.sectionTitle}
                  </h2>
                  <hr />
                </div>
              </div>
              <div className="row">
                <hr />
                <div className="col-md-4 animated hide" data-beat="1">
                  <div className="list-group">
                    {this.state.events.length > 0
                      ? this.state.events.map((event, i) => {
                          return (
                            <React.Fragment key={i}>
                              <span>
                                {this.props.editMode === true ? (
                                  <div className="editMode">
                                    <button
                                      className="btn btn-primary btn-block"
                                      onClick={this.props.toggleAlert.bind(
                                        this,
                                        event.title
                                      )}
                                    >
                                      <i className="fas fa-minus-square" />{" "}
                                      Remove {event.title} from list
                                    </button>{" "}
                                    <div
                                      className={
                                        this.props.alertMessage === event.title
                                          ? "alert alert-danger"
                                          : "alert alert-danger hide"
                                      }
                                      role="alert"
                                    >
                                      Are you sure you want to delete{" "}
                                      {event.title} {"? "}
                                      <button
                                        onClick={this.removeListItem.bind(
                                          this,
                                          i
                                        )}
                                        className="btn btn-danger"
                                      >
                                        YES
                                      </button>{" "}
                                      <button
                                        onClick={this.props.toggleAlert.bind(
                                          this,
                                          ""
                                        )}
                                        className="btn btn-secondary"
                                      >
                                        NO
                                      </button>
                                    </div>
                                  </div>
                                ) : null}
                              </span>
                              <button
                                key={i}
                                type="button"
                                data-num={i}
                                className={
                                  this.state.activeEvent === i
                                    ? "list-group-item list-group-item-action active"
                                    : "list-group-item list-group-item-action"
                                }
                                onClick={this.showEvent.bind(this, i)}
                                onMouseUp={this.props.scrollWindow.bind(
                                  this,
                                  "event"
                                )}
                              >
                                {event.title}
                              </button>
                            </React.Fragment>
                          );
                        })
                      : null}
                  </div>
                </div>
                <div className="col-md-4 animated hide" data-beat="2">
                  <h2 data-target="event">
                    {this.state.events[activeEvent].title}
                  </h2>
                  <ul className="noStyle">
                    <li>{this.state.events[activeEvent].dateTime}</li>
                    <li>{this.state.events[activeEvent].address}</li>
                    <li>
                      {ReactHtmlParser(this.state.events[activeEvent].contact)}
                    </li>
                    <li>
                      {ReactHtmlParser(this.state.events[activeEvent].details)}
                    </li>
                  </ul>
                </div>
                <div className="col-md-4 animated hide" data-beat="3">
                  <iframe
                    className="map"
                    src={
                      "https://www.google.com/maps/embed/v1/place?key=AIzaSyBxvGBPN_lRhoYskabk_lZ5FAo4GIowU6I&q=" +
                      this.state.events[activeEvent].address
                    }
                    allowFullScreen
                  />
                </div>{" "}
              </div>{" "}
              <hr />
            </div>
          ) : null}
        </div>
        {this.props.editMode === true ? (
          <div className="container">
            <div className="row">
              <h2>Add/Edit an event</h2>
              <div className="btn-group btn-block" role="group">
                <button
                  type="button"
                  className={
                    this.state.newEvent === true
                      ? "btn btn-secondary active"
                      : "btn btn-secondary"
                  }
                  onClick={this.addEdit.bind(this, true)}
                >
                  Add Event
                </button>

                <button
                  type="button"
                  className={
                    this.state.newEvent === false
                      ? "btn btn-secondary active"
                      : "btn btn-secondary"
                  }
                  onClick={this.addEdit.bind(this, false)}
                >
                  Edit Event
                </button>
              </div>
              <AddEditEvent
                submitEvent={this.submitEvent}
                newEvent={this.state.newEvent}
                activeEvent={this.state.activeEvent}
                events={this.state.events}
                showEvent={this.showEvent}
              />
            </div>
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

export default ListSection;
