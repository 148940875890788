import React, { Component } from "react";

import ReactQuill from "react-quill";
var toolbarOptions = [
  ["bold", "italic", "underline", "strike"], // toggled buttons
  ["blockquote", "code-block"],

  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: "ordered" }, { list: "bullet" }],
  [{ script: "sub" }, { script: "super" }], // superscript/subscript
  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  [{ direction: "rtl" }], // text direction

  [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ font: [] }],
  [{ align: [] }],

  ["clean"], // remove formatting button
];

class AddEditCarouselItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: "",
      addEdit: "add",
      activeSelection: "",
    }; // You can also pass a Quill Delta here
    this.handleChange = this.handleChange.bind(this);
    this.toggleAddEdit = this.toggleAddEdit.bind(this);
    this.loadSelection = this.loadSelection.bind(this);
  }

  handleChange(value) {
    this.setState({ text: value });
  }

  toggleAddEdit() {
    this.props.resetImgArr();
    [].forEach.call(document.querySelectorAll("input"), function (e) {
      e.value = "";
    });
    document.querySelector("#secondaryText div.ql-editor").innerHTML = "";
    if (this.state.addEdit === "add") {
      this.setState({
        addEdit: "edit",
      });
    } else {
      this.setState({
        addEdit: "add",
      });
    }
  }

  loadSelection() {
    this.props.resetImgArr();
    const secondaryContent = this.props.secondaryContent;
    const editTopic = document.getElementById("editTopic").value;

    this.setState(
      {
        activeSelection: editTopic,
      },
      () => {
        this.props.pushImage(this.state.activeSelection);
      }
    );

    for (let i = 0; i < secondaryContent.length; i++) {
      if (editTopic == secondaryContent[i].secondaryTitle) {
        document.querySelector("input[name='secondaryTitle']").value =
          secondaryContent[i].secondaryTitle;
        document.querySelector("#secondaryText div.ql-editor").innerHTML =
          secondaryContent[i].secondaryText;
      }
    }
  }

  render() {
    const addEdit = this.state.addEdit;
    const secondaryContent = this.props.secondaryContent;
    let imgDisplay = [];

    for (let i = 0; i < this.props.pushImgArr.length; i++) {
      if (this.props.pushImgArr[i].length > 0) {
        imgDisplay.push(this.props.pushImgArr[i]);
      }
    }

    return (
      <div className="addToSecondary col-md-12 editMode">
        <h3>Add/Edit</h3>

        <div class="btn-group btn-block" role="group">
          <button
            type="button"
            class={
              addEdit === "add"
                ? "btn btn-secondary active"
                : "btn btn-secondary"
            }
            onClick={this.toggleAddEdit}
          >
            Add
          </button>

          <button
            type="button"
            class={
              addEdit === "edit"
                ? "btn btn-secondary active"
                : "btn btn-secondary"
            }
            onClick={this.toggleAddEdit}
          >
            Edit
          </button>
        </div>

        {addEdit === "edit" ? (
          <React.Fragment>
            <select
              className="form-control my-3"
              onChange={this.loadSelection}
              id="editTopic"
            >
              <option value="">Select topic to edit</option>
              {secondaryContent
                ? secondaryContent.map((selection, i) => {
                    return (
                      <option value={selection.secondaryTitle} key={i}>
                        {selection.secondaryTitle}
                      </option>
                    );
                  })
                : null}
            </select>
          </React.Fragment>
        ) : (
          <p>
            Submit as many images as you wish. Add a title, body content and
            click "Save section contet" for all the images and text to be
            compiled into a new addition to this section.
          </p>
        )}
        <div className="row">
          {imgDisplay.length > 0
            ? imgDisplay.map((img, i) => {
                return (
                  <div className="col-md-2" key={i}>
                    {" "}
                    <button
                      onClick={this.props.removeImg.bind(
                        this,
                        this.state.activeSelection,
                        i
                      )}
                    >
                      {" "}
                      <i className="fas fa-minus-square" /> Remove:{" "}
                      <img src={img} className="img-fluid" />
                    </button>
                  </div>
                );
              })
            : null}
        </div>

        <div className="form-group">
          <input
            name="addsecondaryImg"
            type="text"
            className="form-control"
            placeholder="Public image address"
            maxLength="1500"
          />
          <button
            className="btn btn-primary btn-block"
            onClick={this.props.pushImage.bind(
              this,
              this.state.activeSelection
            )}
          >
            Submit one image address at a time for carousel
          </button>
        </div>
        <label>
          Number of images added for this content submission:
          {" " + this.props.pushImgArr.length}
        </label>

        <input
          type="text"
          className="form-control"
          placeholder="Title"
          name="secondaryTitle"
          maxLength="255"
        />
        <div id="secondaryText">
          <ReactQuill
            value={this.state.text}
            onChange={this.handleChange}
            modules={AddEditCarouselItem.modules}
            formats={AddEditCarouselItem.formats}
          />

          <input type="hidden" name="secondaryText" value={this.state.text} />
        </div>

        {this.state.addEdit === "add" ? (
          <button
            className="btn btn-primary btn-block"
            onClick={this.props.addToSectionTwo}
          >
            Save section content
          </button>
        ) : (
          <button
            className="btn btn-primary btn-block"
            onClick={this.props.updateSelected.bind(
              this,
              this.state.activeSelection
            )}
          >
            Edit Selection
          </button>
        )}
      </div>
    );
  }
}

/*
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options

AddEditCarouselItem.modules = {
  toolbar: toolbarOptions,
};
 */
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/

AddEditCarouselItem.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "code-block",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
  "table",
]; */

export default AddEditCarouselItem;
