import React, { Component } from "react";

class Contact extends Component {
  constructor() {
    super();
    this.contactScroll = this.contactScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.contactScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.contactScroll);
  }

  contactScroll() {
    const wHeight = window.innerHeight;

    let sectionContact = document
      .querySelector("div[data-contact='1']")
      .getBoundingClientRect().top; // Y
    if (sectionContact < wHeight - 10) {
      document.querySelector("div[data-contact='1']").classList.add("fadeIn");
      document.querySelector("div[data-contact='2']").classList.add("fadeIn");
    }
  }

  render() {
    return (
      <div className="album py-5" data-section="sectionFour">
        <div className="container">
          <div className="row">
            <div className="col-md-12" data-target="contact">
              <h2 className="txtCenter" data-target={this.props.sectionTitle}>
                {this.props.sectionTitle}
              </h2>
              <hr />
            </div>
          </div>
          <div className="row animated" data-contact="1">
            <div className="col-md-3 txtCenter">
              <label>{this.props.topTitle}: </label>
            </div>
            <div className="col-md-3 txtCenter">
              <label>
                <i className="fas fa-home" /> {this.props.address}
              </label>
            </div>
            <div className="col-md-3 txtCenter">
              <label>
                <a href={"tel:" + this.props.phone}>
                  <i className="fas fa-phone" /> {this.props.phone}
                </a>
              </label>
            </div>
            <div className="col-md-3 txtCenter">
              <label>
                <a href={"mailto:" + this.props.email}>
                  <i className="fas fa-envelope" /> {this.props.email}
                </a>
              </label>
            </div>
          </div>
          <div className="row animated" data-contact="2">
            <div className="col-md-12" />
            <hr />
            <iframe
              className="contactMap"
              src={
                "https://www.google.com/maps/embed/v1/place?key=AIzaSyBxvGBPN_lRhoYskabk_lZ5FAo4GIowU6I&q=" +
                this.props.address
              }
              allowFullScreen
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;

/*
      address: "123 Maple Ave. Springfield AZ 85260",
      phone: "555-555-5555",
      email: "some@email.com",


      */
