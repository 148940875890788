//sign up

import React, { Component } from "react";
import * as firebase from "firebase/app"; // Typescript
// import firebase from 'firebase/app'; // JS
import "firebase/functions";
import { app, base, auth, functions } from "./base";

class Auth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: ""
    };
    this.submitSignUp = this.submitSignUp.bind(this);
    this.logOut = this.logOut.bind(this);
    this.submitLogin = this.submitLogin.bind(this);
    this.changeUserStatus = this.changeUserStatus.bind(this);
    //this.addAdmin = this.addAdmin.bind(this);
  }

  /*
  const app = firebase.initializeApp(config);
const base = Rebase.createClass(app.database());
const auth = firebase.auth();
const functions = firebase.functions();
  */

  submitSignUp() {
    const signupForm = document.querySelector("#signup-form");
    /*signupForm.addEventListener("submit", e => {
      e.preventDefault();*/

    // get user info
    const email = document.querySelector("input[name='signup-email']").value;
    const password = document.querySelector("input[name='signup-password']")
      .value;

    // sign up the user
    auth.createUserWithEmailAndPassword(email, password).then(cred => {
      console.log(cred.user);
      // close the signup modal & reset form
      /*const modal = document.querySelector('#modal-signup');
    M.Modal.getInstance(modal).close();
    signupForm.reset();*/
    });
    this.props.clearForms();
    /* });*/
  }

  //listen for auth state changes
  changeUserStatus() {
    const editPanel = document.querySelector("#editOptions");
    auth.onAuthStateChanged(user => {
      if (user) {
        console.log("user: " + JSON.stringify(user));
        this.setState({
          email: user.email
        });
        // editPanel.classList.remove("hide");
        console.log("User Info: " + JSON.stringify(user));
      } else {
        this.setState({
          email: ""
        });
        // editPanel.classList.add("hide");
      }
    });
  }

  logOut() {
    firebase
      .auth()
      .signOut()
      .then(
        function() {
          console.log("this user is signed out.");
        },
        function(error) {
          console.log("auth error: " + error);
        }
      );
    this.changeUserStatus();
    this.props.toggleEditPanel();
  }

  submitLogin(e) {
    e.preventDefault();
    const email = document.querySelector("input[name='login-email']").value;
    const password = document.querySelector("input[name='login-password']")
      .value;
    auth.signInWithEmailAndPassword(email, password).then(cred => {
      //console.log("ignInWithEmailAndPasswor cred: " + JSON.stringify(cred));
      document.querySelector("form#login-form").reset();
      this.changeUserStatus();
    });
  }

  /*addAdmin() {
    const email = document.querySelector("#admin-email").value;
    const addAdminRole = firebase.functions().httpsCallable("addAdminRole");
    //const addAdminRole = functions.httpsCallable("addAdminRole");
    addAdminRole({ email: email }).then(result => {
      console.log("admin result: " + result);
    });
  }*/

  componentDidMount() {
    this.changeUserStatus();
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          <label>Sign up</label>
          <div className="form-group">
            <input
              type="text"
              placeholder="email"
              className="form-control"
              name="signup-email"
            />
            <input
              type="password"
              placeholder="password"
              className="form-control"
              name="signup-password"
            />
            <button
              className="btn btn-primary btn-block"
              onClick={this.submitSignUp}
            >
              Sign Up
            </button>
          </div>
        </div>

        {this.state.email.length === 0 ? (
          <div className="col-md-12">
            <label>Login</label>
            <div className="form-group">
              <form id="login-form" onSubmit={this.submitLogin}>
                <input
                  type="text"
                  placeholder="email"
                  className="form-control"
                  name="login-email"
                />
                <input
                  type="password"
                  placeholder="password"
                  className="form-control"
                  name="login-password"
                />
                <button className="btn btn-primary btn-block">Login</button>
              </form>
            </div>
          </div>
        ) : null}

        {this.state.email.length !== 0 ? (
          <div className="col-md-12">
            <label>
              Logout: <strong> {this.state.email} </strong>
            </label>
            <button className="btn btn-danger btn-block" onClick={this.logOut}>
              Logout
            </button>
          </div>
        ) : null}
      </div>
    );
  }
}

export default Auth;

/*SIGN UP JSX

        <div className="col-md-12">
          <label>Sign up</label>
          <div className="form-group">
            <form id="signup-form" onSubmit={this.submitSignUp}>
              <input
                type="text"
                placeholder="email"
                className="form-control"
                name="signup-email"
              />
              <input
                type="password"
                placeholder="password"
                className="form-control"
                name="signup-password"
              />
              <button className="btn btn-primary btn-block">Sign Up</button>
            </form>
          </div>
        </div>
---------------------------------------------

ADD ADMIN JSX 
            <div className="col-md-12">
              <input type="text" className="form-control" id="admin-email" />
              <button
                className="btn btn-primary btn-block"
                onClick={this.addAdmin}
              >
                Add Admin
              </button>
            </div>


 {"uid":"wB1vxVnFl3T6aD4cY3Oq9XkKVUf2",
 "displayName":null,
 "photoURL":null,
 "email":"aaron@test.com",
 "emailVerified":false,
 "phoneNumber":null,
 "isAnonymous":false,
 "providerData":[{"uid":"aaron@test.com",
 "displayName":null,"photoURL":null,
 "email":"aaron@test.com",
 "phoneNumber":null,
 "providerId":"password"}],
 "apiKey":"AIzaSyDIaQIOtXTg-WyuytNgUMzaLWY_z6yUMA8",
 "appName":"[DEFAULT]",
 "authDomain":"fir-lesson-cord-creator.firebaseapp.com",
 "stsTokenManager":{"apiKey":"AIzaSyDIaQIOtXTg-WyuytNgUMzaLWY_z6yUMA8",
 "refreshToken":"AEu4IL2xZ1tyVjpl2GbR_Tjop3MjHqyMwSunJkl-CV68V4_UKjn09FOrBmRe5IBjahUX7b-bziEL_1jzCraQtentt77EYh0J8DTmtDMZbAoZgC1lGi8Vv_auwuQrRRrn11kd73f1kMaWqSikG7QcQZyMBcd8gO48BQ3gGFnK-8rbGuqr9NkOOlh_SffP_cCUgauADqgjks35KK9l1DmxaJubGMUIdGh8u_9DRNozOhzOFXXyLMZls3U","accessToken":"eyJhbGciOiJSUzI1NiIsImtpZCI6IjVmYjMyOWRmNjdiYjY4NDVkNDk1NDNiMGM0OWIzNWM4ODg1NzllYmEiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vZmlyLWxlc3Nvbi1jb3JkLWNyZWF0b3IiLCJhdWQiOiJmaXItbGVzc29uLWNvcmQtY3JlYXRvciIsImF1dGhfdGltZSI6MTU1NTUzNDA5MCwidXNlcl9pZCI6IndCMXZ4Vm5GbDNUNmFENGNZM09xOVhrS1ZVZjIiLCJzdWIiOiJ3QjF2eFZuRmwzVDZhRDRjWTNPcTlYa0tWVWYyIiwiaWF0IjoxNTU1NTM0MDkwLCJleHAiOjE1NTU1Mzc2OTAsImVtYWlsIjoiYWFyb25AdGVzdC5jb20iLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnsiZW1haWwiOlsiYWFyb25AdGVzdC5jb20iXX0sInNpZ25faW5fcHJvdmlkZXIiOiJwYXNzd29yZCJ9fQ.SeEi96wUmD9FJvK9YTdcSQ_si70bqvAM3S3Awz9vSDAwd-kU_NZWLWnB8PaU0OUnoaKlxEDBHQBwJTZrPbeNmgBb1l5pc9eSy862A1fP6fOfA-h4QEPTVieArwEjLhcJtLxp-XYjbCXxArkTVWfySdkVjrHKLXsS2FIfYrb01BTztocKJH4DI_iZaATNy8CaKBLWRwubdPVRO4Qlz2WncuXPB6lXVL7ujf9lCl5n8kKQpEBpXUaK5d-ETT1xLbUCeWsDh0qWVgNFVdPlECBjv_bq75G9bitmtZybBqPh_vJh3yYuBjvni4OKJlN6dBRQtwDl86RtJ9iU1Gk9qotGbA",
 "expirationTime":1555537690424},
 "redirectEventId":null,
 "lastLoginAt":"1555534090343",
 "createdAt":"1555512278824"}



 <!-- The core Firebase JS SDK is always required and must be listed first -->
<script src="https://www.gstatic.com/firebasejs/6.2.2/firebase-app.js"></script>

<!-- TODO: Add SDKs for Firebase products that you want to use
     https://firebase.google.com/docs/web/setup#config-web-app -->

<script>
  // Your web app's Firebase configuration
  var firebaseConfig = {
  apiKey: "AIzaSyDrSDa0P-cnZXeOeAr75FxFRG3gB3W22YQ",
  authDomain: "web-templates-6a6f1.firebaseapp.com",
  databaseURL: "https://web-templates-6a6f1.firebaseio.com",
  projectId: "web-templates-6a6f1",
  storageBucket: "",
  messagingSenderId: "203277159305",
  appId: "1:203277159305:web:ff969af9f72423f6"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
</script>

// These rules don't allow anyone read or write access to your database
/*

{
  "rules": {
    "users": {
      "$uid": {
        ".read": true,
        ".write": "$uid === auth.uid"
      }
    },
    "teasers": {
      "$uid": {
        ".read": true,
        ".write": "$uid === auth.uid"
      }
    }
  }
}
*/
