import React, { Component } from "react";
import ReactQuill from "react-quill";

class AddEditEvent extends Component {
  constructor(props) {
    let text = "";

    super(props);
    this.state = { text }; // You can also pass a Quill Delta here
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(value) {
    this.setState({ text: value });
  }

  editSelection(whatEvent) {
    this.props.showEvent(whatEvent);
    if (this.props.newEvent === false) {
      for (let i = 0; i < this.props.events.length; i++) {
        if (Number(whatEvent) === i) {
          document.querySelector(
            "[name='eventTitle']"
          ).value = this.props.events[i].title;
          document.querySelector(
            "[name='eventDateTime']"
          ).value = this.props.events[i].dateTime;
          document.querySelector(
            "[name='eventAddress']"
          ).value = this.props.events[i].address;
          document.querySelector(
            "[name='eventContact']"
          ).value = this.props.events[i].contact;
          document.querySelector(
            "#eventDetails .ql-editor"
          ).innerHTML = this.props.events[i].details;
        }
      }
    } else {
      [].forEach.call(document.querySelectorAll("input"), function (e) {
        e.value = "";
      });
      if (document.querySelector("#eventDetails .ql-editor")) {
        document.querySelector("#eventDetails .ql-editor").innerHTML = "";
      }
    }
  }

  render() {
    return (
      <div
        className={
          this.props.newEvent === true
            ? "col-md-12 editMode newEvent"
            : "col-md-12 editMode existingEvent"
        }
      >
        <h3>{this.props.newEvent === true ? "Add Event" : "Edit Event"}</h3>
        {this.props.newEvent === false ? (
          <select
            id="editEvent"
            className="form-control mt-3 mb-3"
            onChange={(e) => this.editSelection(e.target.value)}
          >
            <option>Select an event to modify</option>
            {this.props.events
              ? this.props.events.map((event, i) => {
                  return (
                    <option value={i} key={i}>
                      {"Selected: " + event.title}
                    </option>
                  );
                })
              : null}
          </select>
        ) : null}

        <div className="form-group">
          <input
            type="text"
            name="eventTitle"
            placeholder="title"
            className="form-control"
            maxLength="255"
          />
          <input
            type="text"
            name="eventDateTime"
            placeholder="Date and Time"
            className="form-control"
            maxLength="255"
          />
          <input
            type="text"
            name="eventAddress"
            placeholder="Address"
            className="form-control"
            maxLength="500"
          />
          <input
            type="text"
            name="eventContact"
            placeholder="Contact Info"
            className="form-control"
            maxLength="255"
          />
          <div id="eventDetails">
            <ReactQuill value={this.state.text} onChange={this.handleChange} />
            <input type="hidden" value={this.state.text} name="eventDetails" />
          </div>
          <button
            className="btn btn-primary btn-block"
            onClick={this.props.submitEvent.bind(this, this.props.newEvent)}
          >
            {this.props.newEvent === true ? "Add Event" : "Update Event"}
          </button>
        </div>
      </div>
    );
  }
}

export default AddEditEvent;
