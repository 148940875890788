import React, { Component } from "react";
import { auth, base } from "./base";
import ReactHtmlParser from "react-html-parser";
import Validate from "./Validate";
import AddEditCarouselItem from "./AddEditCarouselItem";
import ModalCarousel from "./ModalCarousel";

class Carousel extends Component {
  //CarouselJSON.json

  constructor(props) {
    super(props);
    this.state = {
      autoSlide: true,
      activeItem: undefined,
      showModal: false,
      secondaryContent: [],
      modalContent: [],
      pushImgArr: [],
      text: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.showGalleryModal = this.showGalleryModal.bind(this);
    this.hideGalleryModal = this.hideGalleryModal.bind(this);
    this.addToSectionTwo = this.addToSectionTwo.bind(this);
    this.deleteFromSectionTwo = this.deleteFromSectionTwo.bind(this);
    this.pushImage = this.pushImage.bind(this);
    this.magnifierOver = this.magnifierOver.bind(this);
    this.magnifierOut = this.magnifierOut.bind(this);
    this.removeImg = this.removeImg.bind(this);
    this.addImg = this.addImg.bind(this);
    this.updateSelected = this.updateSelected.bind(this);
    this.resetImgArr = this.resetImgArr.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleChange(value) {
    this.setState({ text: value });
  }

  componentWillMount() {
    ////////////////////////////////////////////

    let theUser = "";
    let guestUser = this.props.uid;

    auth.onAuthStateChanged((user) => {
      if (user) {
        theUser = user.uid;
      } else {
        theUser = guestUser;
      }

      base.syncState(`secondaryContent/${theUser}`, {
        context: this,
        state: "secondaryContent",
      });
    });
  }

  removeImg(whichSecondary, whichImg) {
    let newImgArr = [];
    let secondaryContent = this.state.secondaryContent;
    let tempImgArr = [];
    let contentNum = 0;

    for (let i = 0; i < secondaryContent.length; i++) {
      if (whichSecondary === secondaryContent[i].secondaryTitle) {
        tempImgArr = secondaryContent[i].secondaryImg;
        contentNum = i;
      }
    }

    if (tempImgArr.length <= 1) {
      alert(
        "There must be at least one image in the carousel. \n Add new images then delete unwanted ones."
      );
      return false;
    } else {
      for (let i = 0; i < tempImgArr.length; i++) {
        if (i !== whichImg) {
          newImgArr.push(tempImgArr[i]);
        }
      }
    }

    secondaryContent[Number(contentNum)].secondaryImg = newImgArr;
    this.setState({
      secondaryContent: secondaryContent,
      pushImgArr: newImgArr,
    });
  }

  addImg(whichSecondary) {
    let secondaryContent = { ...this.state.secondaryContent };
    const addToImgArr = document.querySelector(
      "input[data-addImg='" + whichSecondary + "']"
    ).value;
    secondaryContent[whichSecondary].secondaryImg.push(addToImgArr);
    this.setState({
      secondaryContent: secondaryContent,
    });
    document.querySelector(
      "input[data-addImg='" + whichSecondary + "']"
    ).value = "";
  }

  updateSelected(whichSelection) {
    let secondaryContent = this.state.secondaryContent;
    let updatedBody = "";
    let updatedTitle = "";

    let tempImages = [];
    for (let i = 0; i < this.state.pushImgArr.length; i++) {
      if (this.state.pushImgArr[i].length > 0) {
        tempImages.push(this.state.pushImgArr[i]);
      }
    }
    console.log("tempImages saved: " + tempImages);

    if (document.querySelector("#secondaryText div.ql-editor").innerHTML) {
      updatedBody = document.querySelector("#secondaryText div.ql-editor")
        .innerHTML;
    } else {
      document
        .querySelector("#secondaryText div.ql-editor")
        .classList.add("error");
      return false;
    }

    if (document.querySelector("input[name='secondaryTitle']").value) {
      updatedTitle = document.querySelector("input[name='secondaryTitle']")
        .value;
    } else {
      document
        .querySelector("input[name='secondaryTitle']")
        .classList.add("error");
      return false;
    }

    for (let i = 0; i < secondaryContent.length; i++) {
      if (whichSelection === secondaryContent[i].secondaryTitle) {
        secondaryContent[i].secondaryText = updatedBody;

        secondaryContent[i].secondaryTitle = updatedTitle;
        secondaryContent[i].secondaryImg = tempImages;
        this.setState(
          {
            secondaryContent: secondaryContent,
            pushImgArr: [],
          },
          () => {
            document.querySelector("input[name='secondaryTitle']").value = "";
            document.querySelector("#secondaryText div.ql-editor").innerHTML =
              "";
            document.querySelector("select#editTopic").selectedIndex = 0;
          }
        );
      }
    }
  }

  magnifierOver(whichImg) {
    document
      .querySelector(".focusMagnifier[data-num='" + whichImg + "']")
      .classList.add("pulse");
  }

  magnifierOut(whichImg) {
    document
      .querySelector(".focusMagnifier[data-num='" + whichImg + "']")
      .classList.remove("pulse");
  }

  addToSectionTwo() {
    Validate(["secondaryTitle"]);
    let currentContent = [];
    if (this.state.secondaryContent.length > 0) {
      currentContent = this.state.secondaryContent;
    }

    if (!document.querySelector("input[name='secondaryText']").value) {
      document
        .querySelector("#secondaryText .ql-editor")
        .classList.add("error");
    }

    if (document.querySelector(".error")) {
      return false;
    } else {
      currentContent.push({
        id: Date.now(),
        secondaryImg: this.state.pushImgArr,
        secondaryTitle: document.querySelector("input[name='secondaryTitle']")
          .value,
        secondaryText: document.querySelector("#secondaryText .ql-editor")
          .innerHTML,
      });

      if (this.state.pushImgArr.length === 0) {
        alert("This submission requires at least one image.");
        return false;
      }
      this.setState({
        pushImgArr: [],
        secondaryContent: currentContent,
      });
      document.querySelector("input[name='secondaryTitle']").value = "";
      document.querySelector("#secondaryText .ql-editor").innerHTML = "";

      if (document.querySelectorAll(".error, .addsecondaryImg.error")) {
        [].forEach.call(document.querySelectorAll(".error"), function (e) {
          e.classList.remove("error");
        });
      }
    }
  }

  resetImgArr() {
    this.setState({
      pushImgArr: [],
    });
  }

  pushImage(activeSelection) {
    Validate(["addsecondaryImg"]);

    const secondaryContent = this.state.secondaryContent;
    let currentImgArr = [...this.state.pushImgArr];

    for (let i = 0; i < secondaryContent.length; i++) {
      if (secondaryContent[i].secondaryTitle === activeSelection) {
        for (let j = 0; j < secondaryContent[i].secondaryImg.length; j++) {
          if (
            currentImgArr.indexOf(secondaryContent[i].secondaryImg[j]) === -1
          ) {
            currentImgArr.push(secondaryContent[i].secondaryImg[j]);
          }
        }
      }
    }

    if (document.querySelector("input[name='addsecondaryImg']").value) {
      currentImgArr.push(
        document.querySelector("input[name='addsecondaryImg']").value
      );
    }

    this.setState({
      pushImgArr: currentImgArr,
    });
    document.querySelector("input[name='addsecondaryImg']").value = "";
    document
      .querySelector("input[name='addsecondaryImg']")
      .classList.remove("error");
  }

  deleteFromSectionTwo(whichItem) {
    let revisedContent = [];
    for (let i = 0; i < this.state.secondaryContent.length; i++) {
      if (whichItem !== i) {
        revisedContent.push(this.state.secondaryContent[i]);
      }

      this.setState({
        secondaryContent: revisedContent,
        activeItem: undefined,
      });
    }
  }

  hideGalleryModal() {
    this.setState({
      showModal: false,
    });
  }

  showGalleryModal(contentIndex) {
    this.setState({
      secondaryTitle: this.state.secondaryContent[contentIndex].secondaryTitle,
      showModal: true,
      modalContent: this.state.secondaryContent[contentIndex].secondaryImg,
      modalImgLength: this.state.secondaryContent[contentIndex].secondaryImg
        .length,
    });
  }

  render() {
    const secondaryContent = this.state.secondaryContent;
    let modalContent = this.state.modalContent;

    localStorage.setItem(
      "secondaryContent",
      JSON.stringify(this.state.secondaryContent)
    );

    return (
      <div className="container-fluid" data-section="secondary">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-md-12">
              <h2 className="txtCenter" data-target={this.props.sectionTitle}>
                {this.props.sectionTitle}
              </h2>

              <hr />
            </div>
          </div>

          {secondaryContent && secondaryContent.length > 0
            ? secondaryContent.map((content, i) => {
                return (
                  <div className="row" key={content.id}>
                    <div
                      className="col-md-2 col-sm-5 imgWrap animated fadeInUp"
                      data-scroll={i}
                    >
                      <div
                        onMouseOver={this.magnifierOver.bind(this, i)}
                        onMouseOut={this.magnifierOut.bind(this, i)}
                        data-num={i}
                        className="focusMagnifier animated center"
                        onClick={this.showGalleryModal.bind(this, i)}
                      >
                        <i className="fas fa-search-plus" />
                      </div>{" "}
                      <img
                        onClick={this.showGalleryModal.bind(this, i)}
                        src={content.secondaryImg[0]}
                        alt={content.secondaryTitle}
                        className="img-thumbnail"
                      />
                    </div>
                    <div
                      className="col-md-10 col-sm-7  animated fadeInUp contentTxt"
                      data-scroll={i}
                    >
                      <h4>{content.secondaryTitle}</h4>

                      {ReactHtmlParser(content.secondaryText)}
                    </div>

                    {this.props.editMode ? (
                      <React.Fragment>
                        <hr />
                        <div className="col-md-12  editMode p-3">
                          <button
                            onClick={this.props.toggleAlert.bind(
                              this,
                              content.secondaryTitle
                            )}
                            className="btn btn-primary btn-block"
                          >
                            <i className="fas fa-minus-square" /> Remove "
                            {content.secondaryTitle}" from this section.
                          </button>
                          <div
                            className={
                              this.props.alertMessage === content.secondaryTitle
                                ? "alert alert-danger"
                                : "alert alert-danger hide"
                            }
                            role="alert"
                          >
                            Are you sure you want to delete{" "}
                            {content.secondaryTitle} {"? "}
                            <button
                              onClick={this.deleteFromSectionTwo.bind(this, i)}
                              className="btn btn-danger"
                            >
                              YES
                            </button>{" "}
                            <button
                              onClick={this.props.toggleAlert.bind(this, "")}
                              className="btn btn-secondary"
                            >
                              NO
                            </button>
                          </div>
                        </div>
                      </React.Fragment>
                    ) : null}
                    <hr />
                  </div>
                );
              })
            : null}

          <hr />

          <div>
            {this.props.editMode ? (
              <AddEditCarouselItem
                secondaryContent={this.state.secondaryContent}
                pushImage={this.pushImage}
                pushImgArr={this.state.pushImgArr}
                addToSectionTwo={this.addToSectionTwo}
                removeImg={this.removeImg}
                updateSelected={this.updateSelected}
                resetImgArr={this.resetImgArr}
              />
            ) : null}
          </div>
          {this.state.showModal === true ? (
            <ModalCarousel
              showModal={this.state.showModal}
              secondaryTitle={this.state.secondaryTitle}
              hideGalleryModal={this.hideGalleryModal}
              modalContent={modalContent}
            />
          ) : null}
        </div>

        <div
          onClick={this.closeModal}
          className="modal-backdrop show"
          style={
            this.state.showModal ? { display: "block" } : { display: "none" }
          }
        />
      </div>
    );
  }
}

export default Carousel;

/*

https://lh3.googleusercontent.com/1QWdX6Pg9TOXJe8IPNPGrd8anmFkhjyhOCVsR-mF-kDTRhom8uJLzC-ZTqsd6SkCrVaqxN0wZECtTHk-i21f1bLPng-0cUSuGE3h-Lk6IJRVz9sFq8TfSZoogviGNuQRPjtlhB3DKeFlAeH9JcipFc1x2fcDEsI5KXPFbhLFmd9R_ouWHSnhFGqq3u3HtH1zi-WnvJ4lZNFwTr3o5EH2UdWYhtGyrdhfzyhPNnuE5U0vKpwd5h-iyuzxsJXHPAmdxAuaWdf1S5BK6VZPed5-6IE6rAkshzxj70TnlG2Zsp2TjtLrH06s2WETAFIi7aFzG0_u725O0kY5LXbDSrg0tn_M45f6IhrlFKQotSJZ5ItV_QhoCj6XvMhPIMbuCZSVUQx25hKkGJTjSepGYXy4Q_K3sWI03taxC3_zN7W1R9E8S3QM4M82b3PlLWTbUY3W7YwyBjrJqwzwoTHN4VWZfrwbltoomYjHsoWdcRNSIogtpMUhfOlRD1-pBl56x9-yiR0hzA5hFUBYBw5WFFC6eDhbqGqEKCAt140FDsbrcpp_ucWhkasWsoFSGnc-TQlKxKVeL39MQ5rp7KeLa7G_z6NmgcqopQBd2QVA701M5o3gRDF5Kje_dCgVirSHpXB3fJHsypHYrxjsASvYfBSrDJ5bFHsW07K9jbe_uNbYXmaR8eKttqHIx9891ku48R-I8nH9XOVXiEBhByBFBGgW43ecdw=w1038-h778-no



                      <img
                        onClick={this.showGalleryModal.bind(this, i)}
                        src={content.secondaryImg[0]}
                        alt={content.secondaryTitle}
                        className="img-thumbnail"
                      />
*/
