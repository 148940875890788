import React, { Component } from "react";

const Validate = (fields) => {
  const letterOnly = /^[a-zA-Z\s-.]*$/;
  const numOnly = /^[0-9-().+]+$/;

  if (document.querySelectorAll("input.error, .error.ql-editor")) {
    [].forEach.call(
      document.querySelectorAll("input.error, .error.ql-editor"),
      function (e) {
        e.classList.remove("error");
      }
    );
  }

  for (let i = 0; i < fields.length; i++) {
    let value = "";

    try {
      if (document.querySelector("[name='" + fields[i] + "']").value) {
        value = document.querySelector("[name='" + fields[i] + "']").value;
      }
    } catch (err) {
      document
        .querySelector("[name='" + fields[i] + "']")
        .classList.add("error");
    }

    const maxCharNum = document
      .querySelector("[name='" + fields[i] + "']")
      .getAttribute("maxLength");

    if (value.length <= Number(maxCharNum) && value.length > 0) {
      if (document.querySelector(".error[name='" + fields[i] + "']")) {
        document
          .querySelector("[name='" + fields[i] + "']")
          .classList.remove("error");
      }
    } else {
      document
        .querySelector("[name='" + fields[i] + "']")
        .classList.add("error");
    }

    if (fields[i] === "email") {
      const email = document.querySelector("[name='" + fields[i] + "']").value;
      var atpos = email.indexOf("@");
      var dotpos = email.lastIndexOf(".");
      if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= email.length) {
        document
          .querySelector("[name='" + fields[i] + "']")
          .classList.add("error");
      } //end if
    }

    if (fields[i] === "phone") {
      const phone = document.querySelector("[name='" + fields[i] + "']").value;
      if (
        phone.match(numOnly) == null ||
        phone.length > 20 ||
        phone.length < 9
      ) {
        document
          .querySelector("[name='" + fields[i] + "']")
          .classList.add("error");
      }
    }
  }
};

export default Validate;
